'use client';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { AiListListQueryKey } from './keys';

type ResponseAiPlayerList = {
  success: boolean;
  data: {
    id: string;
    title: string;
    subTitle: string;
    validate: boolean;
    ready: boolean;
    thumb: string;
    updated_at: string;
  }[];
};

type TArg = {
  id: number;
  title: string;
  musician: string;
};

export function useGetAiPlayerListQuery({ id, title, musician }: TArg) {
  return useQuery<ResponseAiPlayerList>({
    queryKey: [AiListListQueryKey, id],
    queryFn: async () => {
      const requestLink = `/api-v2/ai-player?id=${id}&title=${title}&musician=${musician}`;
      const { data } = await axios.get<unknown, ResponseAiPlayerList>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return data as unknown as ResponseAiPlayerList;
    },
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
}
