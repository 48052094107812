import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import { Notes } from '@shared/types';

import { NotesListQueryKey } from './keys';

interface NotesListQueryParams {
  page?: number;
  filter?: {
    idArrangements?: string;
    instrument?: string;
    genre?: string[];
  };
  sort?: {
    field: string;
    asc: 'ASC' | 'DESC';
  };
  favorites_only?: string;
  smart_url?: string;
  order?: string;
  initialData?: NotesResponse;
}

interface NotesResponse {
  data: Notes[];
  pager: {
    page_current: number;
    pages_all: number;
    records_all: number;
    records_per_page: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useNotesListMutation() {
  return useMutation(
    [NotesListQueryKey],
    async ({ page = 1, favorites_only, smart_url, order }: NotesListQueryParams) => {
      const { data } = await axios.get<NotesResponse>(
        `${ENV.API}/notes/?${smart_url ? `smart_url=${smart_url}&` : ''}${queryString.stringify({
          page,
          pagelen: 18,
          lang: ENV.LANG,
          favorites_only,
          order,
        })}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      return data;
    },
  );
}
