import he from 'he';

import { ENV } from '../../config';
import type { Locale } from '../i18n';

type TArg = {
  title?: string;
  description?: string;
  count: number;
  locale: Locale;
};

export const getProductList = ({ title, count, description, locale }: TArg) => ({
  '@context': 'http://schema.org/',
  '@type': 'Product',
  name: title && he.decode(title),
  description: description && he.decode(description),
  offers: {
    '@type': 'AggregateOffer',
    lowPrice: Number(locale.OG_PRODUCT_LIST_MIN_PRICE),
    highPrice: Number(locale.OG_PRODUCT_LIST_MAX_PRICE),
    priceCurrency: ENV.DEFAULT_CERRENCY,
    offerCount: count,
  },
});
