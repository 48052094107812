import { ENV } from '../../config';
import type { Locale } from '../i18n';

type TArg = {
  locale: Locale;
};

export const getWebSite = ({ locale }: TArg) => ({
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: locale.OG_WEBSITE_NAME,
  alternateName: locale.OG_WEBSITE_ALTERNATIVE_NAME,
  url: ENV.STORAGE,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${ENV.STORAGE}/search/?q={q}`,
    'query-input': 'name=q',
  },
});
