import { LS_CURRENCY, ENV } from '@shared/config';

export const setCurrency = (currency: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage?.setItem(LS_CURRENCY, currency);
  }
};

export const getCurrency = () => {
  if (typeof window === 'undefined') {
    return ENV.DEFAULT_CERRENCY;
  }

  let currency = window.localStorage?.getItem(LS_CURRENCY);

  if (!currency) {
    currency = ENV.DEFAULT_CERRENCY;
    setCurrency(currency);
  }

  return currency;
};
