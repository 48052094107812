'use client';

// TODO fix no-unused-vars

/* eslint-disable @typescript-eslint/no-unused-vars */
import { usePathname } from 'next/navigation';
import { useState } from 'react';

export function useUrlParam(name: string): [string | null, (val: string | null) => void] {
  const pathname = usePathname();
  const [searchParams, setSearchParams] = useState(pathname?.split('/'));

  const newSearchParams = usePathname();
  //   const setValue = useCallback(
  //     (value: string | null) => {
  //       if (newSearchParams === null) return;
  //       if (value !== null) {
  //         newSearchParams.set(name, value);
  //       } else {
  //         newSearchParams.delete(name);
  //       }

  //       setSearchParams(newSearchParams);
  //       router.push({ search: "?" + newSearchParams.toString() });
  //     },
  //     [name, router]
  //   );

  //   return [searchParams.get(name), setValue];
  return ['searchParams', () => 'newSearchParams'];
}
