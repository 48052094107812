// TODO fix any

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ENV } from '@shared/config';
import { Currency, Lang, NotesCardQueryData } from '@shared/types';

import { NoteDataQueryKey } from './keys';

export function useNotesCardQuery({
  musician,
  title,
  arrangement,
  id,
}: {
  musician?: string;
  title?: string;
  arrangement?: string;
  id?: string;
}) {
  const requestLink = id
    ? `${ENV.API}/note/${id}/?lang=${Lang.RU}&currency=${Currency.RUB}&token=${ENV.TOKEN}`
    : `${ENV.API}/note/${musician}:${title}:${arrangement}/?lang=${Lang.RU}&currency=${Currency.RUB}&token=${ENV.TOKEN}`;

  return useQuery<NotesCardQueryData['data']>({
    queryKey: [NoteDataQueryKey, musician, title, arrangement, id],
    queryFn: async () => {
      const { data } = await axios.get(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return data.data;
    },
  });
}
