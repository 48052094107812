'use client';

import { type FC, createContext } from 'react';

export interface ModalContextValue {
  modal?: {
    title: string;
    light?: boolean;
    fullSize?: boolean;
    Content: FC<{ onClose?: () => void }>;
  };
}

export const ModalContext = createContext<(modal: ModalContextValue) => void>(() => {});
