// TODO fix any

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getToken } from '../../libs/auth-context/token';

import { PutFavoritesMutationKey } from './keys';

export function usePutFavoritesMutation() {
  return useMutation([PutFavoritesMutationKey], async ({ ids }: { ids: (string | number)[] }) => {
    const token = await getToken();

    const { data } = await axios.get(
      `${ENV.API}/favorites/put/?offers_id=${ids.join(',')}&token=${token}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
    return data.data || [];
  });
}
