import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';

import { getCurrency } from '../../../app/currency';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';
import { BasketQueryKey } from './keys';

type TArg = {
  offer_id: number;
  price: number;
  variant: string;
};

export function useAddToBasketMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ offer_id, variant, price }: TArg) => {
      const token = getClientToken();
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const currency = await getCurrency();

      const requestLink = `${ENV.API}/basket/add/?${queryString.stringify(
        {
          lang: ENV.LANG,
          token,
          currency: currency?.value || ENV.DEFAULT_CERRENCY,
          offer_id,
          variant,
          price,
        },
        {
          skipEmptyString: true,
        },
      )}`;

      const { data } = await axios.get<{ data: { REDIRECT?: string } }>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      return data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [BasketQueryKey],
      });
    },
  });
}
