'use client';

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { P2 } from '../typography';
import styles from './select.module.css';

type Value = {
  label: string;
  value: string;
};

interface SelectProps {
  // label?: string;
  options: Value[];
  // disabled?: boolean;
  onChange?: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  name?: string;
  className?: string;
}

// TODO jsx-a11y
export const Select = ({
  options,
  onChange,
  defaultValue,
  placeholder,
  name,
  className,
}: SelectProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<Value['value'] | undefined>(defaultValue);
  const handleOnChange = useCallback(
    (value: string) => {
      setSelected(value);
      setIsOpen(false);
      onChange?.(value);
    },
    [onChange],
  );

  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleClickOutside = useCallback((e: MouseEvent): void => {
    if (ref?.current && !ref.current?.contains(e.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const selectedText = useMemo(() => {
    if (selected) {
      const selectedOption = options.find((option) => option.value === selected);
      if (selectedOption) {
        return selectedOption.label || selectedOption.value;
      }
    }
    return placeholder || options[0]?.label || options[0]?.value;
  }, [placeholder, selected, options]);

  return (
    <div className={cn(styles.select, className)} ref={ref}>
      <select className={styles.hiddenSelect} name={name}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label || option.value}
          </option>
        ))}
      </select>
      <div className={cn(styles.selectStyled, { [styles.active]: isOpen })} onClick={toggleIsOpen}>
        <P2 className={styles.optionText}>{selectedText}</P2>
      </div>
      <ul className={isOpen ? cn(styles.selectOptions, styles.active) : styles.selectOptions}>
        {options.map((option) => (
          <li
            key={option.value}
            className={
              option.value === selected ? cn(styles.option, styles.selected) : styles.option
            }
            onClick={() => handleOnChange(option.value)}
          >
            <P2 className={styles.optionText}>{option.label || option.value}</P2>
          </li>
        ))}
      </ul>
    </div>
  );
};
