'use client';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { AiListListQueryKey } from './keys';

type TArg = {
  id: string;
};

export function useRunRecognizeVideoMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: TArg) => {
      if (process.env.NODE_ENV !== 'development') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.ym?.(Number(process.env.NEXT_PUBLIC_YA_METRIKA), 'reachGoal', 'AI_PLAYER_RECOGNIZE');
      }

      const requestLink = `/api-v2/ai-player/recognize?id=${id}`;
      await axios.post<{ data: { REDIRECT?: string } }>(requestLink);
      void queryClient.invalidateQueries({
        queryKey: [AiListListQueryKey, id],
      });
    },
  });
}
