// TODO fix any

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ENV } from '@shared/config';
import { AnalogsQueryResponse } from '@shared/types';

import { AnalogsDataQueryKey } from './keys';

interface AnalogsQueryParams {
  musicianIds: string[];
  genreIds: string[];
}

// gets similar musicians list
export function useGetAnalogsQuery({ musicianIds, genreIds }: AnalogsQueryParams) {
  return useQuery<AnalogsQueryResponse['data']>({
    queryKey: [AnalogsDataQueryKey, musicianIds.join(','), genreIds.join(',')],
    queryFn: async () => {
      const { data } = await axios.get(
        `${ENV.API}/get_analogs/?musician_id=${musicianIds.join(',')}&genre_id=${genreIds.join(
          ',',
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      return data.data;
    },
  });
}
