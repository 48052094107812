export const NotesListQueryKey: string = 'notes-list';
export const NoteDataQueryKey: string = 'note-data';
export const AnalogsDataQueryKey: string = 'analogs-data';
export const MusiciansDataQueryKey: string = 'musicians-data';
export const RecentlyViewedQueryKey: string = 'recently-viewed';
export const UpdateNoteViewMutationKey: string = 'update-note';
export const GetFavoritesQueryKey: string = 'get-favorites';
export const PutFavoritesMutationKey: string = 'put-favorites';
export const AddFavoriteMutationKey: string = 'add-favorite';
export const DeleteFavoriteMutationKey: string = 'delete-favorite';
export const GetUserQueryKey: string = 'get-user';
export const EditUserQueryKey: string = 'edit-user';
export const ResetPasswordMutationKey: string = 'reset-pass-user';
export const Ordereds: string = 'orders';
export const OrderedNoted: string = 'orderer-notes';
export const MenuDataQueryKey: string = 'menu-data';
export const BasketQueryKey: string = 'basket';
export const BasketCountQueryKey: string = 'count-basket';
export const AiListListQueryKey: string = 'ai-list';
