// TODO fix any

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { ENV } from '@shared/config';

import { UpdateNoteViewMutationKey } from './keys';

interface UpdateNoteViewMutationParams {
  id: number;
  lang: string;
}

export function useUpdateNoteViewMutation({ id, lang }: UpdateNoteViewMutationParams) {
  return useMutation<any>([UpdateNoteViewMutationKey, id, lang], async () => {
    const { data } = await axios.get(`${ENV.API}/update_note_view/${id}/?lang=${lang}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return data.data;
  });
}
