import queryString from 'query-string';

import { ENV } from '@shared/config';
import { Currency, Notes } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

interface NotesResponse {
  data: Notes[];
  pager: {
    page_current: number;
    pages_all: number;
    records_all: number;
    records_per_page: number;
  };
}

export async function getMusicianNote({
  musician_id,
}: {
  musician_id: number;
}): Promise<NotesResponse['data'] | null> {
  try {
    const requestLink = `${ENV.API}/notes/?${queryString.stringify({
      musician_id,
      currency: Currency.RUB,
      lang: ENV.LANG,
      pagelen: 10,
    })}`;

    const noChach = await getClientNoCache();
    const res = await fetch(requestLink, {
      cache: noChach ? 'no-store' : undefined,
      next: noChach
        ? undefined
        : {
            revalidate: ENV.REVALIDATE_NOTE_S,
            tags: ['NOTES'],
          },
    });
    const { data } = (await res.json()) as NotesResponse;

    return data;
  } catch (e) {
    return null;
  }
}
