'use client';

import cn from 'classnames';
import { useRef, useState } from 'react';
import type { MouseEventHandler, MouseEvent } from 'react';

import { useRatingMutation } from '@shared/api';
import type { Locale } from '@shared/libs';
import { getNounForm } from '@shared/libs';

import styles from './rating.module.css';
import { revalidateNote } from './revalidate';

interface RatingProps {
  rate: string;
  id: number;
  vote_count: number;
  locale: Locale;
  large?: boolean;
  path?: string;
}

const getRating = (e: MouseEvent<HTMLDivElement, unknown>, large: boolean | undefined) => {
  const rect = (e.target as HTMLElement).getBoundingClientRect().left;
  const x = e.clientX - rect;
  const width = large ? 39 : 24;

  let newLocalRate = Math.floor(x / width) + 1;
  if (newLocalRate > 5) {
    newLocalRate = 5;
  }
  return newLocalRate;
};

export const Rating = ({ rate = '0', id, vote_count, locale, large, path }: RatingProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [newRate, setNewRate] = useState<null | number>(null);
  const [isSetRate, setIsSetRate] = useState(false);
  const [initialVoteCount, setInitialVoteCount] = useState(vote_count);
  const ratingMutation = useRatingMutation();

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isSetRate) {
      return;
    }
    const newLocalRate = getRating(e, large);
    setNewRate(newLocalRate);
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isSetRate) {
      return;
    }
    const newLocalRate = getRating(e, large);
    setIsSetRate(true);
    setNewRate(newLocalRate);
    setInitialVoteCount((prev) => prev + 1);
    ratingMutation.mutate({ id, rating: newLocalRate });
    revalidateNote(path || 'NOTES');
  };

  return (
    <>
      <div
        role="presentation"
        className={cn(styles.rating, { [styles.large]: large })}
        ref={ref}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        onMouseLeave={() => {
          if (!isSetRate) {
            setNewRate(null);
          }
        }}
      >
        <span className={cn(styles.star, { [styles.large]: large })} />
        <span
          className={cn(styles.active, { [styles.large]: large })}
          style={{ width: `${Number(newRate || rate) / 0.05}%` }}
        />
      </div>
      <span className={styles.reviews}>
        {`${initialVoteCount} 
        ${getNounForm(initialVoteCount, [
          locale.FEEDBACK_ONE || '',
          locale.FEEDBACK_TWO || '',
          locale.FEEDBACK_MANY || '',
        ])}`}
      </span>
    </>
  );
};
