// TODO fix any

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ENV } from '@shared/config';
import { MusiciansQueryResponse } from '@shared/types';

import { MusiciansDataQueryKey } from './keys';

interface MusiciansQueryParams {
  ids?: string[];
  enabled: boolean;
}

export function useGetMusiciansQuery({ ids, enabled }: MusiciansQueryParams) {
  return useQuery<MusiciansQueryResponse['data']>({
    queryKey: [MusiciansDataQueryKey, ids?.join(',')],
    queryFn: async () => {
      const { data } = await axios.get(`${ENV.API}/musicians/?filter=ID*${ids?.join(',')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return data.data;
    },
    enabled,
  });
}
