import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryString from 'query-string';
import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { BasketCountQueryKey, BasketQueryKey } from './keys';

type TBasketCountResponse = {
  data: {
    count: number;
  };
};

export function useGetCountBasketQuery() {
  return useQuery<TBasketCountResponse>({
    queryKey: [BasketQueryKey, BasketCountQueryKey],
    queryFn: async () => {
      const token = getClientToken();

      const requestLink = `${ENV.API}/basket/count/?${queryString.stringify(
        {
          lang: ENV.LANG,
          token,
        },
        {
          skipEmptyString: true,
        },
      )}`;

      const { data } = await axios.get<unknown, TBasketCountResponse>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return data as unknown as TBasketCountResponse;
    },
    refetchOnWindowFocus: 'always',
  });
}
