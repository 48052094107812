import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { NotesListQueryKey } from './keys';

type TCountFavoritesResponse = {
  data: {
    count: number;
  };
};

export function useGetCountFavoritesQuery() {
  return useQuery<TCountFavoritesResponse>({
    queryKey: [NotesListQueryKey],
    queryFn: async () => {
      const token = getClientToken();

      const requestLink = `${ENV.API}/favorites/count/?${token ? `token=${token}` : ''}`;

      const { data } = await axios.get<unknown, TCountFavoritesResponse>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return data as unknown as TCountFavoritesResponse;
    },
  });
}
