import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryString from 'query-string';
import { ENV } from '@shared/config';
import type { TBasketResponse } from '@shared/types';
import { getCurrency } from '../../../app/currency';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { BasketQueryKey } from './keys';

export function useGetBasketQuery() {
  return useQuery<TBasketResponse>({
    queryKey: [BasketQueryKey],
    queryFn: async () => {
      const token = getClientToken();

      // eslint-disable-next-line @typescript-eslint/await-thenable
      const currency = await getCurrency();

      const requestLink = `${ENV.API}/basket/?${queryString.stringify(
        {
          lang: ENV.LANG,
          token,
          currency: currency?.value || ENV.DEFAULT_CERRENCY,
        },
        {
          skipEmptyString: true,
        },
      )}`;

      const { data } = await axios.get<unknown, TBasketResponse>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return data as unknown as TBasketResponse;
    },
    refetchOnWindowFocus: 'always',
  });
}
