'use client';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { EditUserQueryKey, GetUserQueryKey } from './keys';

interface User {
  email: string;
  last_name: string;
  name: string;
  personal_birthday: string | null;
  photo: string | null;
  subscribe: boolean;
  password: string;
  user_id: string;
}

interface ErrorResponse {
  response: {
    data: {
      error: {
        msg: string;
      };
    };
  };
}

export function useEditUserQuery() {
  const qc = useQueryClient();

  return useMutation<void, ErrorResponse, Partial<User>, unknown>(
    [EditUserQueryKey],
    async (user: Partial<User>) => {
      const token = getClientToken();

      await axios.get(`${ENV.API}/user/edit/?${queryString.stringify({ token, ...user })}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
    {
      onSuccess: () => {
        void qc.invalidateQueries({
          queryKey: [GetUserQueryKey],
        });
      },
    },
  );
}
