import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

export function useViewMutation() {
  return useMutation([], async ({ offer_id }: { offer_id: string | number }) => {
    const token = getClientToken();

    await axios.get(
      `${ENV.API}/update_note_view/${offer_id}/?${queryString.stringify({
        token,
        lang: ENV.LANG,
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  });
}
