'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { AiListListQueryKey } from './keys';

type ResponseAiPlayerItem = {
  success: boolean;
  status: 'recognize' | 'empty' | 'done' | 'waiting';
  data: null | {
    estimated_time?: number;
    done_percent?: number;
    title: string;
    subTitle: string;
    thumb: string;
    validate: boolean;
    ready: boolean;
    mp3: string;
    details: {
      summary: string;
      key: string;
      temp: string;
    };
    chords: {
      position: number;
      start: string | number;
      end: string | number;
      chord: string;
    }[];
    updated_at: string;
  };
};

type OriginalResponseAiPlayerItem = {
  success: boolean;
  status: 'recognize' | 'empty' | 'done' | 'waiting';
  data: null | {
    estimated_time?: number;
    title: string;
    subTitle: string;
    thumb: string;
    validate: boolean;
    ready: boolean;
    mp3: string;
    details: {
      summary: string;
      key: string;
      temp: string;
    };
    chords: {
      position: number;
      offset_start: string | number;
      offset_end: string | number;
      chord: string;
    }[];
  };
};

type TArg = {
  id: string;
  idNote?: number;
};

export function useGetAiPlayerItemQuery({ id, idNote }: TArg) {
  const queryClient = useQueryClient();
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>(undefined);
  const [recognizeIds, setRecognizeIds] = useState<string[]>([]);

  useEffect(() => {
    if (recognizeIds.includes(id)) {
      void queryClient.invalidateQueries({
        queryKey: [AiListListQueryKey, id],
      });
      setRefetchInterval(5000);
    } else {
      setRefetchInterval(undefined);
    }
  }, [id, queryClient, recognizeIds]);

  return useQuery<ResponseAiPlayerItem>({
    queryKey: [AiListListQueryKey, id],
    queryFn: async () => {
      const requestLink = `/api-v2/ai-player/video?id=${id}`;
      const { data } = await axios.get<unknown, { data: OriginalResponseAiPlayerItem }>(
        requestLink,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      if (data?.status === 'recognize') {
        setRecognizeIds((prev) => (prev.includes(id) ? prev : [...prev, id]));
      } else {
        setRecognizeIds((prev) => {
          if (prev.includes(id)) {
            if (idNote) {
              void queryClient.invalidateQueries({
                queryKey: [AiListListQueryKey, idNote],
              });
            }
            return prev.filter((item) => item !== id);
          }

          return prev;
        });
      }

      if (data?.data?.chords) {
        (data as unknown as ResponseAiPlayerItem).data!.chords = data.data.chords.map(
          ({ offset_end, offset_start, ...item }) => ({
            start: offset_start,
            end: offset_end,
            ...item,
          }),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return data as unknown as ResponseAiPlayerItem;
    },
    refetchOnWindowFocus: true,
    refetchInterval,
  });
}
