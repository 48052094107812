import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

type TArg = {
  express: number[];
  promocode?: string;
  email: string;
  preorder?: boolean;
};

type TResponse = {
  data: {
    token: string;
    order_id: number;
    url: string;
  };
};

export function useCreateOrderMutation() {
  const qc = useQueryClient();

  return useMutation(
    [],
    async ({ email, express, promocode, preorder }: TArg) => {
      const token = getClientToken();

      const requestLink = `${ENV.API}/order_add/?${queryString.stringify(
        {
          token,
          email,
          promocode,
          qw: express.length ? express.join(',') : undefined,
          lang: ENV.LANG,
          submitter: preorder ? 'preorder' : undefined,
        },
        {
          skipEmptyString: true,
        },
      )}`;

      const { data } = await axios.get<unknown>(requestLink, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      return data as TResponse;
    },
    {
      onSuccess: () => {
        void qc.invalidateQueries({
          queryKey: [],
        });
      },
    },
  );
}
