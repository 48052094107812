/* eslint-disable */

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import { TSearchResponse } from '@shared/types';

interface NotesListQueryParams {
  page?: number;
  q?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useNotesSearchMutation() {
  return useMutation([], async ({ page = 1, q }: NotesListQueryParams) => {
    const requestLink = `${ENV.API}/search/?q=${q}&${queryString.stringify(
      {
        page,
        pagelen: 20,
        lang: ENV.LANG,
      },
      {
        skipEmptyString: true,
      },
    )}`;
    console.log(requestLink);

    const { data } = await axios.get<TSearchResponse>(requestLink, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return data;
  });
}
