/* eslint-disable react/no-unused-prop-types */
import cn from 'classnames';
import { Unbounded } from 'next/font/google';
import type { ReactNode } from 'react';

import styles from './typography.module.css';

export const unbounded = Unbounded({
  weight: ['300', '400', '500', '700', '900'],
  subsets: ['latin', 'cyrillic'],
});

type TProps = {
  children?: ReactNode;
  html?: string;
  className?: string;
  itemProp?: string;
};

type TUnboundedFontProps = TProps & {
  unboundedFont?: boolean;
};

export const H1 = ({ children, className }: TProps) => {
  return <h1 className={cn(unbounded.className, styles.h1, className)}>{children}</h1>;
};

export const H2 = ({ children, className, html, itemProp }: TProps) => {
  return (
    <h2
      className={cn(unbounded.className, styles.h2, className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={html ? { __html: html } : undefined}
      itemProp={itemProp}
    >
      {children}
    </h2>
  );
};

export const H3 = ({ children, unboundedFont = true, className }: TUnboundedFontProps) => {
  return (
    <h3
      className={
        unboundedFont ? cn(unbounded.className, styles.h3, className) : cn(styles.h3, className)
      }
    >
      {children}
    </h3>
  );
};

export const H4 = ({ children, unboundedFont = true, className }: TUnboundedFontProps) => {
  return (
    <h4
      className={
        unboundedFont ? cn(unbounded.className, styles.h4, className) : cn(styles.h4, className)
      }
    >
      {children}
    </h4>
  );
};

export const Int1 = ({ children, className }: TProps) => {
  return <span className={cn(styles.int1, className)}>{children}</span>;
};

export const Int2 = ({ children, className }: TProps) => {
  return <span className={cn(styles.int2, className)}>{children}</span>;
};

export const Int3 = ({ children, className }: TProps) => {
  return <span className={cn(styles.int3, className)}>{children}</span>;
};

export const Int4 = ({ children, className }: TProps) => {
  return <span className={cn(styles.int4, className)}>{children}</span>;
};

export const P1 = ({ children, className, html }: TProps) => {
  return (
    <p
      className={cn(styles.p1, className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={html ? { __html: html } : undefined}
    >
      {children}
    </p>
  );
};

export const P2 = ({ children, className }: TProps) => {
  return <p className={cn(styles.p2, className)}>{children}</p>;
};

export const P3 = ({ children, className }: TProps) => {
  return <p className={cn(styles.p3, className)}>{children}</p>;
};

export const P4 = ({ children, className }: TProps) => {
  return <p className={cn(styles.p4, className)}>{children}</p>;
};

export const UnboundedFont = ({ children, className, itemProp }: TProps) => {
  return (
    <div className={cn(unbounded.className, className)} itemProp={itemProp}>
      {children}
    </div>
  );
};
