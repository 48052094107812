'use client';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import { SortType, OrderItem } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';
import { OrderedNoted } from './keys';

interface QueryParams {
  page?: number;
  pagelen?: number;
  sort?: SortType;
}

interface OrderedNotesResponse {
  data: OrderItem[];
  pager: {
    page_current: number;
    pages_all: number;
    records_all: number;
    records_per_page: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useGetOrderedNotesQuery({ page = 1, pagelen = 12, sort }: QueryParams) {
  return useQuery({
    queryKey: [OrderedNoted],
    queryFn: async () => {
      const token = getClientToken();
      const { data } = await axios.get<OrderedNotesResponse>(
        `${ENV.API}/ordered_notes/?${queryString.stringify({ token, page, lang: ENV.LANG, pagelen })}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      return data;
    },
  });
}
