'use client';

import { createContext } from 'react';

interface Context {
  isAuth: boolean | null;
  setIsAuth: (val: boolean) => void;
}

export const AuthContext = createContext<Context>({
  isAuth: null,
  setIsAuth: () => {},
});
