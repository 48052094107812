import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import type { MusiciansQueryResponse } from '@shared/types';

import { NotesListQueryKey } from './keys';

export function useMusiciansMutation() {
  return useMutation(
    [NotesListQueryKey],
    async ({ page = 1, alpha }: { page?: number; alpha?: string }) => {
      const path = alpha ? `musicians/alpha/${alpha}` : 'musicians';
      const requestLink = `${ENV.API}/${path}/?${queryString.stringify({
        lang: ENV.LANG,
        pagelen: 12,
        page,
      })}`;

      const { data } = await axios.get<MusiciansQueryResponse>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return data;
    },
  );
}
