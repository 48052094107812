'use client';

import { useCallback, useEffect } from 'react';

import { useUrlParam } from './use-url-param';

export function usePagination(): [number, (page: number | null) => void] {
  const [pageParam, setPageParam] = useUrlParam('page');

  const page = Number(pageParam) || 1;
  const setPage = useCallback(
    (num: number | null) => setPageParam(num !== null ? String(num) : num),
    [setPageParam],
  );

  useEffect(() => {
    if (pageParam === null) {
      return;
    }

    const num = Number(pageParam);

    if (Number.isNaN(num) || num < 1) {
      setPage(1);
    }
  }, [pageParam, setPage]);

  return [page, setPage];
}
