'use client';

import { useCallback, useEffect, useState } from 'react';
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetFavoritesQuery,
} from '@shared/api';
import { revalidateNote } from './revalidateNote';

type TArg = {
  id?: string | number;
  count?: number;
  old_favorite?: boolean;
  path?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useFavourites = ({ id, count, old_favorite, path }: TArg) => {
  const { data: favorites } = useGetFavoritesQuery();
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (favorites) {
      const isCheck = favorites.data.some((idFav) => idFav.toString() === id?.toString());
      setIsFavorite(isCheck);
    }
  }, [favorites, id]);

  const addFavoriteMutation = useAddFavoriteMutation();
  const deleteFavoriteMutation = useDeleteFavoriteMutation();

  const [countFavorite, setCountFavorite] = useState(count || 0);

  const toggleFavoriteById = useCallback(
    async (toggleId: string | number) => {
      if (!isFavorite) {
        setCountFavorite((prev) => prev + 1);
        setIsFavorite(true);
        await addFavoriteMutation.mutateAsync({ id: toggleId });
      } else {
        setCountFavorite((prev) => prev - 1);
        setIsFavorite(false);
        await deleteFavoriteMutation.mutateAsync({ offer_id: toggleId });
      }
      revalidateNote(path);
    },
    [addFavoriteMutation, deleteFavoriteMutation, isFavorite, path],
  );

  const toggleFavorite = useCallback(
    () => (id ? toggleFavoriteById(id) : () => {}),
    [id, toggleFavoriteById],
  );

  return {
    isFavorite,
    toggleFavorite,
    toggleFavoriteById,
    countFavorite,
  };
};
