'use client';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { ResetPasswordMutationKey } from './keys';

interface Reset {
  password: string;
  new_password: string;
  new_password2: string;
}

interface ErrorResponse {
  response: {
    data: {
      error: {
        msg: string;
      };
    };
  };
}

export function useResetPasswordQuery() {
  return useMutation<void, ErrorResponse, Reset, unknown>(
    [ResetPasswordMutationKey],
    async (data: Reset) => {
      const token = getClientToken();

      await axios.get(
        `${ENV.API}/user/reset_password/?${queryString.stringify({ token, ...data })}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
    },
  );
}
