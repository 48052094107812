'use client';

import cn from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';

import { typographyStyles } from '../typography';
import styles from './sub-menu.module.css';

interface SubMenuProps {
  data: Array<{
    title: string;
    path: string;
  }>;
  children?: ReactNode;
}

export const SubMenu = ({ data, children }: SubMenuProps) => {
  const pathname = usePathname();

  return (
    <div className={styles.container}>
      {data.map(({ title, path }) => (
        <Link
          key={path}
          href={path}
          className={cn(typographyStyles.p1, styles.link, { [styles.active]: pathname === path })}
          prefetch={false}
        >
          {title}
        </Link>
      ))}
      {children}
    </div>
  );
};
