'use client';

import cn from 'classnames';
import React, { type ReactNode, forwardRef, useCallback } from 'react';

import styles from './button.module.css';

export enum ButtonColor {
  BLACK = 'black',
  WHITE = 'white',
  GREEN = 'green',
  PURPLE = 'purple',
}

export enum ButtonSize {
  L = 'l',
  M = 'm',
  S = 's',
}

interface ButtonProps {
  children?: ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  size?: ButtonSize;
  color?: ButtonColor;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled = false,
      onClick,
      size = ButtonSize.M,
      color = ButtonColor.BLACK,
      className,
      type = 'button',
      loading,
    },
    ref,
  ) => {
    const handleClick = useCallback(
      (event: React.MouseEvent) => {
        if (disabled) {
          return;
        }

        onClick?.(event);
      },
      [onClick, disabled],
    );

    return (
      <button
        className={cn(styles.button, styles[size], styles[color], className, {
          [styles.loading]: loading,
        })}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={handleClick}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
