'use client';

import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { ENV } from '@shared/config';

import styles from './breadcrumbs.module.css';

interface BreadcrumbsProps {
  paths: Array<
    | {
        title: string;
        path: string;
      }
    | Array<{
        title: string;
        path: string;
      }>
  >;
  currentTitle: string;
  bgColor?: string;
  className?: string;
}

export const Breadcrumbs = ({ paths, currentTitle, bgColor, className }: BreadcrumbsProps) => {
  const pathname = usePathname();

  return (
    <div className={cn(styles.breadcrumbs, className)} style={{ backgroundColor: bgColor }}>
      <div
        className={styles.pageHistory}
        itemProp="http://schema.org/breadcrumb"
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        {paths.map((item, index) => {
          if (Array.isArray(item)) {
            return (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={styles.sublink}
              >
                {item.map(({ title, path }) => (
                  <span
                    key={path}
                    itemScope
                    itemProp="itemListElement"
                    itemType="http://schema.org/ListItem"
                  >
                    <Link href={path || '/'} prefetch={false} itemProp="item">
                      <span itemProp="name">{he.decode(title || '')}</span>
                      <meta itemProp="position" content={(index + 1).toString()} />
                    </Link>
                  </span>
                ))}
              </span>
            );
          }

          const { title, path } = item;

          return (
            <span
              key={path}
              itemScope
              itemProp="itemListElement"
              itemType="http://schema.org/ListItem"
            >
              <Link href={path || '/'} className={styles.link} prefetch={false} itemProp="item">
                <span itemProp="name">{he.decode(title || '')}</span>
                <meta itemProp="position" content={(index + 1).toString()} />
              </Link>
            </span>
          );
        })}

        <span itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
          <span itemProp="name">{he.decode(currentTitle)}</span>
          <meta itemProp="position" content={(paths.length + 1).toString()} />
          <meta itemProp="item" content={`${ENV.STORAGE}${pathname}`} />
        </span>
      </div>
    </div>
  );
};
