import { ENV } from '../config';

import type { TMenu } from '../types';

export const getMenu = async () => {
  try {
    const requestLink = `${ENV.API}/get_menu/?lang=${ENV.LANG}`;

    const res = await fetch(requestLink, {
      next: { revalidate: ENV.REVALIDATE_STATIC_S, tags: ['STATIC'] },
    });
    const { data } = (await res.json()) as { data: TMenu[] };

    return data;
  } catch (e) {
    return null;
  }
};
