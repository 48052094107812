import queryString from 'query-string';

import { ENV } from '@shared/config';
import { Currency, Notes } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

interface NotesResponse {
  data: Notes[];
}

export async function getFreeNotes(): Promise<NotesResponse['data'] | null> {
  const requestLink = `${ENV.API}/notes/?${queryString.stringify(
    {
      currency: Currency.RUB,
      lang: ENV.LANG,
      smart_url: '/category/isfree-is-y/apply/',
      pagelen: 100,
    },
    {
      skipEmptyString: true,
    },
  )}`;

  const noChach = await getClientNoCache();
  const res = await fetch(requestLink, {
    cache: noChach ? 'no-store' : undefined,
    next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_S },
  });
  const { data } = (await res.json()) as NotesResponse;

  return data;
}
