/* eslint-disable jsx-a11y/control-has-associated-label,jsx-a11y/click-events-have-key-events */

'use client';

import cn from 'classnames';
import he from 'he';
import Image from 'next/image';
import { useState } from 'react';
import { ENV } from '@shared/config';

import type { Locale } from '@shared/libs';
import { H1 } from '../typography';
import styles from './page-card.module.css';

type TProps = {
  title: string;
  text?: string;
  text_2?: string;
  img?: string;
  accent?: boolean;
  locale: Locale;
};
export const PageCard = ({ title, text, text_2, img, accent, locale }: TProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={cn(styles.card, {
        [styles.withImage]: Boolean(img),
        [styles.withHiddenImage]: Boolean(img) && !open,
      })}
    >
      <div className={styles.textSection}>
        <H1 className={styles.title}>{he.decode(title || 'H1 Page')}</H1>
        <div
          className={cn(styles.textWrap, {
            [styles.open]: open,
          })}
        >
          {text && (
            // eslint-disable-next-line react/no-danger
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: he.decode(text) }} />
          )}
          {text_2 && (
            // eslint-disable-next-line react/no-danger
            <p className={styles.text2} dangerouslySetInnerHTML={{ __html: he.decode(text_2) }} />
          )}
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <p
          className={cn(styles.showMore, {
            [styles.open]: open,
          })}
          onClick={() => setOpen(true)}
        >
          {locale.SHOW_MORE || 'SHOW_MORE'}
        </p>
      </div>
      {img && (
        <div
          className={cn(styles.imgSection, {
            [styles.hideImg]: !open,
          })}
        >
          <div
            className={cn(styles.imgWrap, {
              [styles.accent]: accent,
            })}
          >
            <Image
              // src="https://f.note-store.com/upload/iblock/054/pjyne3zvhgtfkpb3084eosh9axzoou21/guitar_1.jpg"
              src={`${ENV.STORAGE}${img}`}
              alt={title}
              fill
              className={styles.img}
            />
          </div>
        </div>
      )}
    </div>
  );
};
