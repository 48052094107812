export const ENV = {
  TOKEN: process.env.NEXT_PUBLIC_TOKEN || 'TOKEN',
  API: `${process.env.NEXT_PUBLIC_HOST}/api`,
  AI_HOST_API: process.env.NEXT_PUBLIC_AI_HOST_API || '/',
  STORAGE: process.env.NEXT_PUBLIC_HOST || '/',
  API_PLAYER: `${process.env.NEXT_PUBLIC_HOST}/`,
  EMAIL: process.env.NEXT_PUBLIC_EMAIL || '',
  LANG: process.env.NEXT_PUBLIC_LANG_SITE || '',
  COPYRIGHT_HOST: process.env.NEXT_PUBLIC_COPYRIGHT_HOST || '',
  DEFAULT_CERRENCY: process.env.NEXT_PUBLIC_DEFAULT_CERRENCY || 'USD',
  FILTER_ID_IS_FREE: Number(process.env.NEXT_PUBLIC_FILTER_ID_IS_FREE) || 0,
  FILTER_IS_FREE_VALUE: process.env.NEXT_PUBLIC_FILTER_IS_FREE_VALUE || 'Y',
  // FILTER_IS_FREE_CONTROL_NAME: process.env.NEXT_PUBLIC_FILTER_IS_FREE_CONTROL_NAME || '',
  FILTER_ID_INSTRUMENTS: Number(process.env.NEXT_PUBLIC_FILTER_ID_INSTRUMENTS) || 0,
  FILTER_ID_COMPLEXITY: Number(process.env.NEXT_PUBLIC_FILTER_ID_COMPLEXITY) || 0,
  FILTER_ID_ARRANGEMENT: Number(process.env.NEXT_PUBLIC_FILTER_ID_ARRANGEMENT) || 0,
  FILTER_ID_GENRE: Number(process.env.NEXT_PUBLIC_FILTER_ID_GENRE) || 0,
  FILTER_ID_MUSICIAN: Number(process.env.NEXT_PUBLIC_FILTER_ID_MUSICIAN) || 0,
  // REG_LIMIT_TIME_MS: Number(process.env.NEXT_PUBLIC_REG_LIMIT_TIME_MS) || 1000 * 60 * 30,
  // REG_LIMIT_COUNT: Number(process.env.NEXT_PUBLIC_REG_LIMIT_COUNT) || 5,
  REG_LIMIT_TIME_MS: 100,
  REG_LIMIT_COUNT: 100,
  REVALIDATE_WITH_TOKEN_S: 3,
  REVALIDATE_NOTE_S: 60 * 60 * 24 * 3,
  REVALIDATE_NOTE_LIST_S: 60 * 60 * 24 * 3,
  REVALIDATE_STATIC_S: 60 * 60 * 24 * 30,
};
