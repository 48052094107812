'use client';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import { SortType, Order } from '@shared/types';
import { getCurrency } from '../../../app/currency';

// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';
import { Ordereds } from './keys';

interface QueryParams {
  page?: number;
  pagelen?: number;
  sort?: SortType;
}

interface OrdersResponse {
  data: Order[];
  pager: {
    page_current: number;
    pages_all: number;
    records_all: number;
    records_per_page: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useGetOrdersQuery({ page = 1, pagelen = 12, sort }: QueryParams) {
  return useQuery({
    queryKey: [Ordereds],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const currency = await getCurrency();
      const token = getClientToken();

      const { data } = await axios.get<OrdersResponse>(
        `${ENV.API}/orders/?${queryString.stringify(
          {
            token,
            page,
            pagelen,
            currency: currency?.value,
            lang: ENV.LANG,
          },
          {
            encode: false,
          },
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      return data;
    },
  });
}
