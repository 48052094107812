'use client';

import cn from 'classnames';
import { useRouter, usePathname } from 'next/navigation';
import React from 'react';
import ReactPaginate from 'react-paginate';

import type { Locale } from '@shared/libs';

import styles from './pagination.module.css';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  locale: Locale;
  query: string;
  queryTail?: string;
}

// TODO fix no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Pagination = ({
  totalPages,
  currentPage,
  locale,
  query,
  queryTail,
}: PaginationProps) => {
  const pathname = usePathname();
  const router = useRouter();

  if (totalPages < 2) {
    return null;
  }

  return (
    <div style={{ height: '50px', position: 'relative' }}>
      <div style={{ width: '100%', overflow: 'auto', position: 'absolute' }}>
        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          previousLabel={locale.PAGINATION_PREV}
          nextLabel={locale.PAGINATION_NEXT}
          containerClassName={styles.pagination}
          pageLinkClassName={styles.pageNum}
          activeClassName={styles.active}
          disabledClassName={styles.disabled}
          breakClassName={styles.break}
          nextClassName={cn(styles.navigate, styles.next)}
          previousClassName={cn(styles.navigate, styles.previous)}
          hrefBuilder={(page) =>
            page > 1
              ? `${pathname}?${query}=${page}${queryTail ? `&${queryTail}` : '#'}`
              : `${pathname}${queryTail ? `?${queryTail}` : '#'}`
          }
          onPageChange={({ selected }) => {
            router.push(
              selected > 0
                ? `${pathname}?${query}=${selected + 1}${queryTail ? `&${queryTail}` : '#'}`
                : `${pathname}${queryTail ? `?${queryTail}` : '#'}`,
            );
          }}
          breakLabel="..."
          disableInitialCallback
        />
      </div>
    </div>
  );
};
