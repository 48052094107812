import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import { Notes } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { NotesListQueryKey } from './keys';

interface NotesResponse {
  data: Notes[];
  pager: {
    page_current: number;
    pages_all: number;
    records_all: number;
    records_per_page: number;
  };
}

interface NotesListQueryParams {
  page?: number;
  pagelen?: number;
  filter?: {
    idArrangements?: string;
    instrument?: string;
    genre?: string[];
  };
  sort?: {
    field: string;
    asc: 'ASC' | 'DESC';
  };
  favorites_only?: string;
  smart_url?: string;
  initialData?: NotesResponse;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useNotesListQuery({
  page = 1,
  pagelen = 12,
  filter,
  favorites_only,
  smart_url,
  initialData,
}: NotesListQueryParams) {
  return useQuery({
    queryKey: [NotesListQueryKey, filter?.idArrangements, filter?.instrument],
    queryFn: async () => {
      const token = getClientToken();

      const { data } = await axios.get<NotesResponse>(
        `${ENV.API}/notes/?${smart_url ? `smart_url=${smart_url}&` : ''}${token ? `token=${token}&` : ''}${queryString.stringify(
          {
            page,
            pagelen,
            lang: ENV.LANG,
            favorites_only,
          },
          {
            skipEmptyString: true,
          },
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      return data;
    },
    initialData,
  });
}
