'use client';

// TODO fix jsx-a11y
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import cn from 'classnames';
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { ENV } from '@shared/config';
import type { Locale } from '@shared/libs';
import { MusicInfo } from '@shared/types';

import { parseMusicians } from '../parse-musicians';
import styles from './player.module.css';

const addZero = (content: number) => `0${content}`;

const getTime = (time: number) =>
  `${Math.floor(time / 60)}:${
    Math.floor(time - Math.floor(time / 60) * 60).toString().length === 1
      ? addZero(Math.floor(time - Math.floor(time / 60) * 60))
      : Math.floor(time - Math.floor(time / 60) * 60)
          .toString()
          .slice(0, 2)
  }`;

export const Player = ({
  musicians,
  title,
  mp3,
  locale,
}: {
  musicians: MusicInfo[];
  title: string;
  mp3: string;
  locale: Locale;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progressTime, setProgressTime] = useState(0);
  const [playPercent, setPlayPercent] = useState(0);
  const [isDrag, setIsDrag] = useState(false);

  useEffect(() => {
    return () => {
      setProgressTime(0);
      setPlayPercent(0);
    };
  }, []);

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      audioRef?.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef?.current
        ?.play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          // TODO notification
          console.error(error);
        });
    }
  }, [isPlaying]);

  const changeCurrentTime = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const playheadWidth = timelineRef?.current?.offsetWidth || 0;
      const offsetLeft = timelineRef.current?.getBoundingClientRect().left || 0;
      const userClickWidth = e.pageX - offsetLeft - 10;
      const userClickWidthInPercent = (userClickWidth * 100) / playheadWidth;

      if (userClickWidthInPercent <= 100) {
        if (!isPlaying) {
          audioRef?.current
            ?.play()
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              // TODO notification
              console.error(error);
            });
        }

        if (audioRef?.current?.currentTime !== undefined) {
          audioRef.current.currentTime =
            ((audioRef?.current?.duration || 0) * userClickWidthInPercent) / 100;

          setProgressTime(((audioRef?.current?.duration || 0) * userClickWidthInPercent) / 100);
          setPlayPercent(userClickWidthInPercent);

          if (!isPlaying) {
            audioRef.current
              .play()
              .then(() => {
                setIsPlaying(true);
              })
              .catch((error) => {
                // TODO notification
                console.error(error);
              });
          }
        }
      }
    },
    [isPlaying],
  );

  const startDrag = useCallback<MouseEventHandler<HTMLElement>>((event) => {
    setIsDrag(true);
    setIsPlaying(false);
    audioRef?.current?.pause();

    event.stopPropagation();
    event.preventDefault();
  }, []);

  const drag = useCallback<MouseEventHandler<Document>>((event) => {
    let x = event.pageX - (timelineRef.current?.getBoundingClientRect().left || 0) - 10;

    if (x < 0) {
      x = 0;
    }
    if (x > (timelineRef.current?.clientWidth || 0) - 10) {
      x = (timelineRef.current?.clientWidth || 0) - 10;
    }
    const playheadWidth = timelineRef.current?.offsetWidth || 0 || 0;
    const userClickWidthInPercent = (x * 100) / playheadWidth;

    setProgressTime(((audioRef.current?.duration || 0) * userClickWidthInPercent) / 100);
    setPlayPercent(userClickWidthInPercent);
  }, []);

  const stopDrag = useCallback<MouseEventHandler<Document>>(() => {
    setIsDrag(false);

    if (audioRef.current?.currentTime !== undefined) {
      audioRef.current.currentTime = ((audioRef.current?.duration || 0) * playPercent) / 100;
    }
    audioRef.current
      ?.play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        // TODO notification
        console.error(error);
      });
  }, [playPercent]);

  useEffect(() => {
    if (isDrag) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      document?.addEventListener('mousemove', drag);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      document?.addEventListener('mouseup', stopDrag);
    }
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      document?.removeEventListener('mousemove', drag);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      document?.removeEventListener('mouseup', stopDrag);
    };
  }, [drag, stopDrag, isDrag]);

  const initProgressBar = useCallback(() => {
    if (audioRef?.current?.currentTime === audioRef?.current?.duration) {
      setProgressTime(0);
      setPlayPercent(0);
      return;
    }
    setProgressTime(audioRef?.current?.currentTime || 0);
    setPlayPercent(
      100 * ((audioRef?.current?.currentTime || 0) / (audioRef?.current?.duration || 1)),
    );
  }, []);

  return (
    <div className={styles.player}>
      <div onClick={togglePlay} className={styles.playerButtonWrap}>
        <div
          className={
            isPlaying
              ? cn(styles.pauseButton, styles.playerControlButton, styles.show)
              : cn(styles.playerControlButton, styles.pauseButton)
          }
        />
        <div
          className={
            isPlaying
              ? cn(styles.playerControlButton, styles.playButton)
              : cn(styles.playerControlButton, styles.playButton, styles.show)
          }
        />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>
          {locale.PLAYER_TEXT} {parseMusicians(musicians)} - {title}
        </div>
        <audio ref={audioRef} onTimeUpdate={initProgressBar}>
          <source src={`${ENV.API_PLAYER}${mp3}`} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
        <div className={styles.progress} onClick={changeCurrentTime} ref={timelineRef}>
          <div className={styles.progressAll} />
          <div className={styles.progressCurrent} style={{ width: `${playPercent}%` }} />
          <div
            className={styles.progressDot}
            onMouseDown={startDrag}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onDragStart={() => false}
            style={{ left: `calc(${playPercent}% - 4px)` }}
          />
        </div>
        <div className={styles.timing}>
          <div>{getTime(progressTime)}</div>
          <div>{audioRef?.current?.duration ? getTime(audioRef?.current?.duration) : '-- --'}</div>
        </div>
      </div>
    </div>
  );
};
