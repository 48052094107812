export const getNounForm = (value: number, words: string[]) => {
  const absVal = Math.abs(value) % 100;
  const num = absVal % 10;

  if (absVal > 10 && absVal < 20) {
    return words[2];
  }
  if (num > 1 && num < 5) {
    return words[1];
  }
  if (num === 1) {
    return words[0];
  }

  return words[2];
};
