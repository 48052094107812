import queryString from 'query-string';
import { ENV } from '@shared/config';
import type { Musician } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

type TMeta = {
  meta_description: string;
  meta_h1: string;
  meta_keywords: string;
  meta_title: string;
};

export async function getMusician({
  alias,
}: {
  alias: string;
}): Promise<{ data: Musician; meta: TMeta }> {
  const requestLink = `${ENV.API}/musician/?uri=${alias}&${queryString.stringify(
    {
      lang: ENV.LANG,
    },
    {
      skipEmptyString: true,
    },
  )}`;

  const noChach = await getClientNoCache();
  const res = await fetch(requestLink, {
    cache: noChach ? 'no-store' : undefined,
    next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_LIST_S, tags: ['NOTES'] },
  });
  const data = (await res.json()) as { data: Musician; meta: TMeta };

  return data;
}
