import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';

import { NotesListQueryKey } from './keys';

interface FilterQueryParams {
  filters?: string[];
}

export type FilterItem = {
  NAME: string;
  VALUES: Record<
    string,
    {
      SORT: number;
      VALUE: string;
      CONTROL_NAME: string;
      CHECKED?: boolean;
      ELEMENT_COUNT?: string | null;
    }
  >;
};

export interface FilterResponse {
  data: {
    ITEMS: {
      50: FilterItem;
      61: FilterItem;
      176: FilterItem;
      183: FilterItem;
      63: {
        NAME: string;
        VALUES: {
          [key: string]: {
            CHECKED?: boolean;
            CONTROL_NAME: string;
            ELEMENT_COUNT?: string | null;
          };
        };
      };
    };
    SEF_SET_FILTER_URL: string;
    SEF_DEL_FILTER_URL: string;
    ELEMENT_COUNT: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSmartSearchMutattion() {
  return useMutation([NotesListQueryKey], async ({ filters }: FilterQueryParams) => {
    const filtersStr = filters?.length ? `${filters.join('=Y&')}=Y&` : '';
    const { data } = await axios.get<FilterResponse>(
      `${ENV.API}/get_filter/?${filtersStr}${queryString.stringify({
        set_filter: filtersStr ? `y` : undefined,
        lang: ENV.LANG,
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
    return data;
  });
}
