'use client';

import { createContext } from 'react';

type TCurrencyContext = {
  currencySelect: string;
  setCurrencySelect: (val: string) => void;
};

export const CurrencyContext = createContext<TCurrencyContext>({
  currencySelect: '',
  setCurrencySelect: () => {},
});
