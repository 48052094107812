import { ENV } from '../../config';

import type { Locale } from './type';

type LocalResponse = {
  data: Locale;
};

export const getLocale = async () => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/get_lang/?lang=${ENV.LANG}`, {
    next: { revalidate: ENV.REVALIDATE_STATIC_S, tags: ['STATIC'] },
  });
  const { data } = (await res.json()) as LocalResponse;

  return data;
};
