import queryString from 'query-string';

import { ENV } from '@shared/config';
import { MusiciansQueryResponse } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

export async function getAnalogsMusicians({
  musicianIds = [],
  genreIds = [],
}: {
  musicianIds?: string[];
  genreIds?: string[];
}) {
  const noChach = await getClientNoCache();
  try {
    const requestLinkAnalogsMusicians = `${ENV.API}/get_analogs/?${queryString.stringify({
      musician_id: musicianIds.join(','),
      genre_id: genreIds.join(','),
    })}`;

    const respAnalogsMusiciansIds = await fetch(requestLinkAnalogsMusicians, {
      cache: noChach ? 'no-store' : undefined,
      next: noChach
        ? undefined
        : {
            revalidate: ENV.REVALIDATE_NOTE_LIST_S,
            tags: ['NOTES'],
          },
    });
    const { data: analogsMusiciansIds } = (await respAnalogsMusiciansIds.json()) as {
      data: string[];
    };

    const requestLinkMusicians = `${ENV.API}/musicians/?${queryString.stringify({
      filter: `ID*${analogsMusiciansIds?.join(',')}`,
      lang: ENV.LANG,
    })}`;
    const resMusicians = await fetch(requestLinkMusicians, {
      cache: noChach ? 'no-store' : undefined,
      next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_LIST_S, tags: ['NOTES'] },
    });
    const dataMusicians = (await resMusicians.json()) as MusiciansQueryResponse;

    return dataMusicians.data;
  } catch (e) {
    return [];
  }
}
