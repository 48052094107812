import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';

import { GetUserQueryKey } from './keys';

interface Login {
  login: string;
  password: string;
}

interface LoginResponse {
  data: {
    data: {
      token: string;
    };
  };
}

interface ErrorResponse {
  response: {
    data: {
      error: {
        msg: string;
      };
    };
  };
}

export function useLoginMutation() {
  return useMutation<string, ErrorResponse, Login, unknown>(
    [GetUserQueryKey],
    async ({ login, password }: Login) => {
      const { data } = await axios.get<unknown, LoginResponse>(
        `${ENV.API}/auth/login/?${queryString.stringify({ login, password })}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      return data.data.token;
    },
  );
}
