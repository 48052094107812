export {
  H1,
  H2,
  H3,
  H4,
  Int1,
  Int2,
  Int3,
  Int4,
  P1,
  P2,
  P3,
  P4,
  UnboundedFont,
  unbounded,
} from './typography';
export { default as typographyStyles } from './typography.module.css';
