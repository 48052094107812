import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import type { MusiciansQueryResponse } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';
import { BasketQueryKey } from './keys';

type TArg = {
  offer_id: number;
};

export function useRemoveFromBasketMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ offer_id }: TArg) => {
      const token = getClientToken();

      const requestLink = `${ENV.API}/basket/delete/?${queryString.stringify(
        {
          token: token || undefined,
          offer_id,
          lang: ENV.LANG,
        },
        {
          skipEmptyString: true,
        },
      )}`;

      await axios.get<MusiciansQueryResponse>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [BasketQueryKey] });
    },
  });
}
