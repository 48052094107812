// TODO fix any

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
import { RecentlyViewedResponse, Notes } from '@shared/types';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { RecentlyViewedQueryKey } from './keys';

interface NotesResponse {
  data: Notes[];
  pager: {
    page_current: number;
    pages_all: number;
    records_all: number;
    records_per_page: number;
  };
}
export function useGetViewedQuery() {
  return useQuery<Notes[]>({
    queryKey: [RecentlyViewedQueryKey],
    queryFn: async () => {
      const token = getClientToken();

      const { data: dataViewed } = await axios.get<RecentlyViewedResponse>(
        `${ENV.API}/get_viewed/?${queryString.stringify({
          token,
          lang: ENV.LANG,
        })}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      if (dataViewed.data?.length) {
        const { data } = await axios.get<NotesResponse>(
          `${ENV.API}/notes/?${queryString.stringify({
            token,
            filter: `ID*${dataViewed.data.join(',')}`,
            lang: ENV.LANG,
          })}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        );

        return data.data;
      }

      return [];
    },
    retryOnMount: true,
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
    cacheTime: 0,
    staleTime: 0,
  });
}
