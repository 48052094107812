'use client';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';

// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';
import { GetUserQueryKey } from './keys';

interface User {
  email: string;
  last_name: string;
  name: string;
  personal_birthday: string | null;
  photo: string | null;
  subscribe: boolean;
  user_id: string;
}

interface UserResponse {
  data: User;
}
export function useGetUserQuery() {
  return useQuery(
    [GetUserQueryKey],
    async () => {
      const token = getClientToken();
      const { data } = await axios.get<UserResponse>(
        `${ENV.API}/user/?${queryString.stringify(
          { token },
          {
            skipEmptyString: true,
          },
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      if (data.data) {
        return data.data as unknown as UserResponse['data'];
      }
      return { isError: 456 };
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
}
