import queryString from 'query-string';
import { ENV } from '@shared/config';
import type { MusiciansQueryResponse } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

export async function getMusicians({
  page = 1,
  id,
  alpha,
}: {
  page?: number;
  alpha?: string;
  id?: string;
}): Promise<MusiciansQueryResponse> {
  const path = alpha ? `musicians/alpha/${alpha}` : 'musicians';
  const requestLink = `${ENV.API}/${path}/?${queryString.stringify(
    {
      lang: ENV.LANG,
      pagelen: 12,
      page,
      filter: id && `filter=ID*${id}`,
    },
    {
      skipEmptyString: true,
    },
  )}`;

  const noChach = await getClientNoCache();
  const res = await fetch(requestLink, {
    cache: noChach ? 'no-store' : undefined,
    next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_LIST_S, tags: ['NOTES'] },
  });
  const data = (await res.json()) as MusiciansQueryResponse;

  return data;
}
