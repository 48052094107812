import appStoreIcon from './app-store.svg';
import arrowDiagonalWhiteIcon from './arrow-diagonal-white.svg';
import arrowDiagonalIcon from './arrow-diagonal.svg';
import arrowDownIcon from './arrow-down.svg';
import chevronDownIcon from './chevron-down.svg';
import chevronUpIcon from './chevron-up.svg';
import closeDarkIcon from './close-dark.svg';
import closeIcon from './close.svg';
import dropdownIcon from './dropdown-icon.svg';
import exportIcon from './export.svg';
import facebookIcon from './facebook.svg';
import favoriteIcon from './favorite.svg';
import flagRusIcon from './flag-rus.svg';
import googlePlayIcon from './google-play.svg';
import guaranteeIcon from './guarantee.svg';
import importIcon from './import.svg';
import likeGreyIcon from './like-grey.svg';
import likeRedIcon from './like-red.svg';
import likeIcon from './like.svg';
import linkIcon from './link.svg';
import logoIcon from './logo.svg';
import menuIcon from './menu.svg';
import noteIcon from './note.svg';
import pauseIcon from './pause.svg';
import paymentsRuIcon from './payments-ru.svg';
import pinterestIcon from './pinterest.svg';
import playIcon from './play.svg';
import pplayIcon from './pplay.svg';
import printIcon from './print.svg';
import radiobuttonFilledIcon from './radiobutton-filled.svg';
import radiobuttonIcon from './radiobutton.svg';
import removeIcon from './remove.svg';
import searchIcon from './search.svg';
import settingIcon from './setting.svg';
import shoppingCartIcon from './shopping-cart.svg';
import starGoldIcon from './star-gold.svg';
import starIcon from './star.svg';
import userIcon from './user.svg';
import viewIcon from './view.svg';
import vkIcon from './vk.svg';
import youtubeIcon from './youtube.svg';
import zoomIcon from './zoom.svg';

export {
  appStoreIcon,
  arrowDiagonalIcon,
  arrowDiagonalWhiteIcon,
  arrowDownIcon,
  closeIcon,
  closeDarkIcon,
  chevronDownIcon,
  chevronUpIcon,
  dropdownIcon,
  exportIcon,
  facebookIcon,
  favoriteIcon,
  flagRusIcon,
  googlePlayIcon,
  guaranteeIcon,
  importIcon,
  likeIcon,
  likeGreyIcon,
  linkIcon,
  logoIcon,
  menuIcon,
  noteIcon,
  pauseIcon,
  paymentsRuIcon,
  pinterestIcon,
  playIcon,
  pplayIcon,
  printIcon,
  radiobuttonFilledIcon,
  radiobuttonIcon,
  removeIcon,
  searchIcon,
  shoppingCartIcon,
  starGoldIcon,
  starIcon,
  userIcon,
  viewIcon,
  vkIcon,
  youtubeIcon,
  zoomIcon,
  settingIcon,
  likeRedIcon,
};
