import queryString from 'query-string';
import { ENV } from '@shared/config';
import type { TThematicsSectionGroupResponse } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

type Arg = {
  group: string;
  section: string;
};

export async function getThematicsSectionGroup({
  group,
  section,
}: Arg): Promise<TThematicsSectionGroupResponse> {
  const requestLink = `${ENV.API}/popular/${section}/${group}/?${queryString.stringify(
    {
      lang: ENV.LANG,
      length: 100,
    },
    {
      skipEmptyString: true,
    },
  )}`;

  const noChach = await getClientNoCache();
  const res = await fetch(requestLink, {
    cache: noChach ? 'no-store' : undefined,
    next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_LIST_S, tags: ['NOTES'] },
  });
  const data = (await res.json()) as TThematicsSectionGroupResponse;

  return data;
}
