import { Currency } from './currency';
import { Lang } from './lang';
import { ArrangementInfo, ItemInfo, MusicInfo, PriceInfo } from './notes-card';
import { Pager } from './pager';

export interface NoteInfo {
  arrangement?: {
    id: string;
    title: string;
  };
  id: number;
  title: string;
  code: string;
  rating: number;
  year: string;
  url: string;
  views: number;
  likes: number;
  vote_count: number;
  img: {
    src: string;
    src_256_362: string;
    width: number;
    height: number;
  };
  musicians: MusicInfo[];
  price: {
    BASE: PriceInfo;
    MIDI: PriceInfo;
    FINAL: PriceInfo;
  };
  genres: ItemInfo[];
  arrangements: ArrangementInfo[];
  instruments: ItemInfo[];
  complexity: {
    level: 10 | 20 | 30;
    title: string;
  } | null;
  favorite?: boolean;
}

export interface NotesListQueryData {
  data: NoteInfo[];
  lang: Lang;
  userId: string;
  // TODO no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: any;
  pager: Pager;
}

export const NotesListInitialDataRus = {
  data: [
    {
      arrangement: {
        code: '',
        title: '',
      },
      id: '44678',
      title: 'Мальчик на девятке',
      code: 'malchik-na-devyatke',
      rating: '3.87',
      year: '2021',
      views: 1000,
      likes: 17,
      vote_count: 1,
      url: '',
      img: {
        src: '/upload/resize_cache/iblock/31c/185_172_2/Malchik-na-devyatke.jpg',
        width: 185,
        height: 172,
      },
      musicians: [
        {
          id: '35958',
          title: 'DEAD BLONDE',
        },
      ],
      price: {
        PRINT_VALUE: '$5.20',
        VALUE: 5.2,
        CURRENCY: Currency.USD,
        STORE_ID: null,
        variant: 'BASE',
        offer_id: 44680,
      },
      genres: [
        {
          code: 'dance-music',
          title: 'Танцевальная музыка',
        },
      ],
      arrangements: [
        {
          code: 'piano-easy',
          title: 'Пианино.Easy',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dead-blonde/malchik-na-devyatke/piano-easy',
          offer_id: 44679,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-solo',
          title: 'Пианино.Соло',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dead-blonde/malchik-na-devyatke/piano-solo',
          offer_id: 44680,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-vocal',
          title: 'Пианино&Вокал',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dead-blonde/malchik-na-devyatke/piano-vocal',
          offer_id: 44681,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'guitar-tab-easy',
          title: 'Гитара.Табы (Лёгкие)',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dead-blonde/malchik-na-devyatke/guitar-tab-easy',
          offer_id: 44682,
          instruments: null,
        },
        {
          code: 'guitar-tab',
          title: 'Гитара.Табы',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dead-blonde/malchik-na-devyatke/guitar-tab',
          offer_id: 44683,
          instruments: [
            {
              code: 'guitar',
              title: 'Гитара',
            },
          ],
        },
      ],
    },
    {
      id: '46113',
      title: 'Птичка',
      code: 'ptichka',
      rating: '3.93',
      year: '2021',
      img: {
        src: '/upload/resize_cache/iblock/161/185_172_2/Ptichka.jpg',
        width: 185,
        height: 172,
      },
      musicians: [
        {
          id: '1626',
          title: 'HammAli & Navai',
        },
      ],
      price: {
        PRINT_VALUE: '$5.20',
        VALUE: 5.2,
        CURRENCY: Currency.USD,
        STORE_ID: null,
        variant: 'BASE',
        offer_id: 46115,
      },
      genres: [
        {
          code: 'rap',
          title: 'Рэп/ хип-хоп',
        },
      ],
      arrangements: [
        {
          code: 'piano-easy',
          title: 'Пианино.Easy',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/ptichka/piano-easy',
          offer_id: 46114,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-solo',
          title: 'Пианино.Соло',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/ptichka/piano-solo',
          offer_id: 46115,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-vocal',
          title: 'Пианино&Вокал',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/ptichka/piano-vocal',
          offer_id: 46116,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'guitar-tab-easy',
          title: 'Гитара.Табы (Лёгкие)',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/ptichka/guitar-tab-easy',
          offer_id: 46117,
          instruments: [
            {
              code: 'guitar',
              title: 'Гитара',
            },
          ],
        },
        {
          code: 'guitar-tab',
          title: 'Гитара.Табы',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/ptichka/guitar-tab',
          offer_id: 46118,
          instruments: [
            {
              code: 'guitar',
              title: 'Гитара',
            },
          ],
        },
      ],
    },
    {
      id: '17830',
      title: 'Пчеловод',
      code: 'pchelovod',
      rating: '4.09',
      year: '2019',
      img: {
        src: '/upload/resize_cache/iblock/441/185_172_2/RASA-_-Pchelovod.png',
        width: 185,
        height: 172,
      },
      musicians: [
        {
          id: '823',
          title: 'RASA',
        },
      ],
      price: {
        PRINT_VALUE: '$5.20',
        VALUE: 5.2,
        CURRENCY: Currency.USD,
        STORE_ID: null,
        variant: 'BASE',
        offer_id: 17832,
      },
      genres: [
        {
          code: 'pop',
          title: 'Поп',
        },
      ],
      arrangements: [
        {
          code: 'piano-easy',
          title: 'Пианино.Easy',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'rasa/pchelovod/piano-easy',
          offer_id: 17831,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-solo',
          title: 'Пианино.Соло',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'rasa/pchelovod/piano-solo',
          offer_id: 17832,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-vocal',
          title: 'Пианино&Вокал',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'rasa/pchelovod/piano-vocal',
          offer_id: 17833,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'guitar-tab',
          title: 'Гитара.Табы',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'rasa/pchelovod/guitar-tab',
          offer_id: 33429,
          instruments: [
            {
              code: 'guitar',
              title: 'Гитара',
            },
          ],
        },
      ],
    },
    {
      id: '38403',
      title: 'Я так соскучился',
      code: 'ya-tak-soskuchilsya',
      rating: '3.91',
      year: '2017',
      img: {
        src: '/upload/resize_cache/iblock/d2e/185_172_2/Pornofilmy-_-YA-tak-soskuchilsya.jpg',
        width: 185,
        height: 172,
      },
      musicians: [
        {
          id: '24172',
          title: 'Порнофильмы',
        },
      ],
      price: {
        PRINT_VALUE: '$5.20',
        VALUE: 5.2,
        CURRENCY: Currency.USD,
        STORE_ID: null,
        variant: 'BASE',
        offer_id: 38405,
      },
      genres: [
        {
          code: 'rock',
          title: 'Рок',
        },
      ],
      arrangements: [
        {
          code: 'piano-easy',
          title: 'Пианино.Easy',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'porn-films/ya-tak-soskuchilsya/piano-easy',
          offer_id: 38404,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-solo',
          title: 'Пианино.Соло',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'porn-films/ya-tak-soskuchilsya/piano-solo',
          offer_id: 38405,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-vocal',
          title: 'Пианино&Вокал',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'porn-films/ya-tak-soskuchilsya/piano-vocal',
          offer_id: 38406,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
      ],
    },
    {
      id: '36001',
      title: 'Юность',
      code: 'yunost',
      rating: '3.85',
      year: '2020',
      img: {
        src: '/upload/resize_cache/iblock/d38/185_172_2/YUnost.jpg',
        width: 185,
        height: 172,
      },
      musicians: [
        {
          id: '8893',
          title: 'Dabro',
        },
      ],
      price: {
        PRINT_VALUE: '$5.20',
        VALUE: 5.2,
        CURRENCY: Currency.USD,
        STORE_ID: null,
        variant: 'BASE',
        offer_id: 36003,
      },
      genres: [
        {
          code: 'pop',
          title: 'Поп',
        },
      ],
      arrangements: [
        {
          code: 'piano-easy',
          title: 'Пианино.Easy',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dabro/yunost/piano-easy',
          offer_id: 36002,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-solo',
          title: 'Пианино.Соло',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dabro/yunost/piano-solo',
          offer_id: 36003,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-vocal',
          title: 'Пианино&Вокал',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dabro/yunost/piano-vocal',
          offer_id: 36004,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'guitar-tab',
          title: 'Гитара.Табы',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dabro/yunost/guitar-tab',
          offer_id: 42935,
          instruments: [
            {
              code: 'guitar',
              title: 'Гитара',
            },
          ],
        },
        {
          code: 'guitar-tab-easy',
          title: 'Гитара.Табы (Лёгкие)',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'dabro/yunost/guitar-tab-easy',
          offer_id: 42936,
          instruments: [
            {
              code: 'guitar',
              title: 'Гитара',
            },
          ],
        },
      ],
    },
    {
      id: '10060',
      title: 'Слишком Влюблен',
      code: 'slishkom-vlyublen',
      rating: '3.73',
      year: '2012',
      img: {
        src: '/upload/resize_cache/iblock/f28/185_172_2/Too-In-Love.jpg',
        width: 185,
        height: 172,
      },
      musicians: [
        {
          id: '6831',
          title: 'Нервы',
        },
      ],
      price: {
        PRINT_VALUE: '$5.20',
        VALUE: 5.2,
        CURRENCY: Currency.USD,
        STORE_ID: null,
        variant: 'BASE',
        offer_id: 10062,
      },
      genres: [
        {
          code: 'pop',
          title: 'Поп',
        },
      ],
      arrangements: [
        {
          code: 'piano-easy',
          title: 'Пианино.Easy',
          price: {
            BASE: {
              PRINT_VALUE: '$7.20',
              VALUE: 7.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$11',
              VALUE: 11,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$14.50',
              VALUE: 14.5,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'Nerves/slishkom-vlyublen/piano-easy',
          offer_id: 10061,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-solo',
          title: 'Пианино.Соло',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'Nerves/slishkom-vlyublen/piano-solo',
          offer_id: 10062,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-vocal',
          title: 'Пианино&Вокал',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'Nerves/slishkom-vlyublen/piano-vocal',
          offer_id: 10063,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
      ],
    },
    {
      id: '17898',
      title: 'Прятки',
      code: 'pryatki',
      rating: '3.74',
      year: '2019',
      img: {
        src: '/upload/resize_cache/iblock/9d3/185_172_2/Pryatki.jpg',
        width: 185,
        height: 172,
      },
      musicians: [
        {
          id: '1626',
          title: 'HammAli & Navai',
        },
      ],
      price: {
        PRINT_VALUE: '$5.20',
        VALUE: 5.2,
        CURRENCY: Currency.USD,
        STORE_ID: null,
        variant: 'BASE',
        offer_id: 17900,
      },
      genres: [
        {
          code: 'pop',
          title: 'Поп',
        },
      ],
      arrangements: [
        {
          code: 'piano-easy',
          title: 'Пианино.Easy',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/pryatki/piano-easy',
          offer_id: 17899,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-solo',
          title: 'Пианино.Соло',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/pryatki/piano-solo',
          offer_id: 17900,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'piano-vocal',
          title: 'Пианино&Вокал',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/pryatki/piano-vocal',
          offer_id: 17901,
          instruments: [
            {
              code: 'piano',
              title: 'Пианино',
            },
          ],
        },
        {
          code: 'guitar-tab',
          title: 'Гитара.Табы',
          price: {
            BASE: {
              PRINT_VALUE: '$5.20',
              VALUE: 5.2,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            MIDI: {
              PRINT_VALUE: '$7.90',
              VALUE: 7.9,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
            FINAL: {
              PRINT_VALUE: '$10.40',
              VALUE: 10.4,
              CURRENCY: Currency.USD,
              STORE_ID: null,
            },
          },
          url: 'hammali-navai/pryatki/guitar-tab',
          offer_id: 33367,
          instruments: [
            {
              code: 'guitar',
              title: 'Гитара',
            },
          ],
        },
      ],
    },
    // {
    //   id: "37078",
    //   title: "Cadillac",
    //   code: "cadillac",
    //   rating: "3.85",
    //   year: "2020",
    //   img: {
    //     src: "/upload/resize_cache/iblock/851/185_172_2/Cadillac.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "6666",
    //       title: "Morgenshtern",
    //     },
    //     {
    //       id: "495",
    //       title: "Элджей",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 37080,
    //   },
    //   genres: {
    //     code: "rap",
    //     title: "Рэп/ хип-хоп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "eldzhey/cadillac/piano-easy",
    //       offer_id: 37079,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "eldzhey/cadillac/piano-solo",
    //       offer_id: 37080,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "eldzhey/cadillac/piano-vocal",
    //       offer_id: 37081,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "770",
    //   title: "Мотылёк",
    //   code: "motylek",
    //   rating: "3.57",
    //   year: "2013",
    //   img: {
    //     src: "/upload/resize_cache/iblock/c5b/185_172_2/_-_-_-_.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "559",
    //       title: "Макс Корж",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 771,
    //   },
    //   genres: {
    //     code: "rap",
    //     title: "Рэп/ хип-хоп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maks-korzh/motylek/piano-solo",
    //       offer_id: 771,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maks-korzh/motylek/piano-easy",
    //       offer_id: 1891,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maks-korzh/motylek/piano-vocal",
    //       offer_id: 1892,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "guitar-tab",
    //       title: "Гитара.Табы",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maks-korzh/motylek/guitar-tab",
    //       offer_id: 33373,
    //       instruments: [
    //         {
    //           code: "guitar",
    //           title: "Гитара",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "12966",
    //   title: "Experience",
    //   code: "experience",
    //   rating: "3.85",
    //   year: "2013",
    //   img: {
    //     src: "/upload/resize_cache/iblock/c1d/185_172_2/Ludovico-Einaudi-_-Experience.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "1588",
    //       title: "Людовико Эйнауди",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$7.20",
    //     VALUE: 7.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 12967,
    //   },
    //   genres: {
    //     code: "classic",
    //     title: "Классика/ Современная классика",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "ludovico-einaudi/experience/piano-solo",
    //       offer_id: 12967,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "22163",
    //   title: "Секунду назад",
    //   code: "sekundu-nazad",
    //   rating: "3.63",
    //   year: "2019",
    //   img: {
    //     src: "/upload/resize_cache/iblock/3d2/185_172_2/Diana-Arbenina-_-Sekundu-nazad.png",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "2760",
    //       title: "Диана Арбенина",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 22165,
    //   },
    //   genres: {
    //     code: "rock",
    //     title: "Рок",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "diana-arbenina/sekundu-nazad/piano-easy",
    //       offer_id: 22164,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "diana-arbenina/sekundu-nazad/piano-solo",
    //       offer_id: 22165,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "diana-arbenina/sekundu-nazad/piano-vocal",
    //       offer_id: 22166,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "guitar-tab",
    //       title: "Гитара.Табы",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "diana-arbenina/sekundu-nazad/guitar-tab",
    //       offer_id: 33379,
    //       instruments: [
    //         {
    //           code: "guitar",
    //           title: "Гитара",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "47680",
    //   title: "Beggin",
    //   code: "beggin-2",
    //   rating: "4.05",
    //   year: "2017",
    //   img: {
    //     src: "/upload/resize_cache/iblock/f3e/185_172_2/M_neskin-_-Beggin.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "42147",
    //       title: "Måneskin",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 47682,
    //   },
    //   genres: {
    //     code: "rock",
    //     title: "Рок",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maneskin/beggin-2/piano-easy",
    //       offer_id: 47681,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maneskin/beggin-2/piano-solo",
    //       offer_id: 47682,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maneskin/beggin-2/piano-vocal",
    //       offer_id: 47683,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "guitar-tab-easy",
    //       title: "Гитара.Табы (Лёгкие)",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maneskin/beggin-2/guitar-tab-easy",
    //       offer_id: 47684,
    //       instruments: null,
    //     },
    //     {
    //       code: "guitar-tab",
    //       title: "Гитара.Табы",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "maneskin/beggin-2/guitar-tab",
    //       offer_id: 47685,
    //       instruments: [
    //         {
    //           code: "guitar",
    //           title: "Гитара",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "29938",
    //   title: "колыбельная",
    //   code: "kolybelnaya-4",
    //   rating: "4.01",
    //   year: "2019",
    //   img: {
    //     src: "/upload/resize_cache/iblock/1ee/185_172_2/kolybelnaya.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "2918",
    //       title: "Rauf & Faik",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 29940,
    //   },
    //   genres: {
    //     code: "pop",
    //     title: "Поп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/kolybelnaya-4/piano-easy",
    //       offer_id: 29939,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/kolybelnaya-4/piano-solo",
    //       offer_id: 29940,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/kolybelnaya-4/piano-vocal",
    //       offer_id: 29941,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "47274",
    //   title: "Я в моменте",
    //   code: "ya-v-momente",
    //   rating: "3.54",
    //   year: "2021",
    //   img: {
    //     src: "/upload/resize_cache/iblock/4c6/185_172_2/YA-v-momente.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "9435",
    //       title: "Джарахов",
    //     },
    //     {
    //       id: "1441",
    //       title: "Markul",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 47276,
    //   },
    //   genres: {
    //     code: "rap",
    //     title: "Рэп/ хип-хоп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "markul/ya-v-momente/piano-easy",
    //       offer_id: 47275,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "markul/ya-v-momente/piano-solo",
    //       offer_id: 47276,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "markul/ya-v-momente/piano-vocal",
    //       offer_id: 47277,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "guitar-tab-easy",
    //       title: "Гитара.Табы (Лёгкие)",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "markul/ya-v-momente/guitar-tab-easy",
    //       offer_id: 47278,
    //       instruments: null,
    //     },
    //     {
    //       code: "guitar-tab",
    //       title: "Гитара.Табы",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "markul/ya-v-momente/guitar-tab",
    //       offer_id: 47279,
    //       instruments: [
    //         {
    //           code: "guitar",
    //           title: "Гитара",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "9312",
    //   title: "Девочка С КАРЕ",
    //   code: "devochka-s-kare",
    //   rating: "3.54",
    //   year: "2019",
    //   img: {
    //     src: "/upload/resize_cache/iblock/456/185_172_2/Devochka-S-KARE.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "9311",
    //       title: "МУККА",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 9314,
    //   },
    //   genres: {
    //     code: "rock",
    //     title: "Рок",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "mukka/devochka-s-kare/piano-easy",
    //       offer_id: 9313,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "mukka/devochka-s-kare/piano-solo",
    //       offer_id: 9314,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "mukka/devochka-s-kare/piano-vocal",
    //       offer_id: 9315,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "guitar-tab",
    //       title: "Гитара.Табы",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "mukka/devochka-s-kare/guitar-tab",
    //       offer_id: 33426,
    //       instruments: [
    //         {
    //           code: "guitar",
    //           title: "Гитара",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "11651",
    //   title: "Без тебя я не я",
    //   code: "bez-tebya-ya-ne-ya",
    //   rating: "3.93",
    //   year: "2019",
    //   img: {
    //     src: "/upload/resize_cache/iblock/0c3/185_172_2/JONY_-HammAli-_-Navai-_-Bez-tebya-ya-ne-ya.png",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "8912",
    //       title: "JONY",
    //     },
    //     {
    //       id: "1626",
    //       title: "HammAli & Navai",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 11653,
    //   },
    //   genres: {
    //     code: "rap",
    //     title: "Рэп/ хип-хоп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "hammali-navai/bez-tebya-ya-ne-ya/piano-easy",
    //       offer_id: 11652,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "hammali-navai/bez-tebya-ya-ne-ya/piano-solo",
    //       offer_id: 11653,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "hammali-navai/bez-tebya-ya-ne-ya/piano-vocal",
    //       offer_id: 11654,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "22810",
    //   title: "Комета",
    //   code: "kometa",
    //   rating: "3.87",
    //   year: "2019",
    //   img: {
    //     src: "/upload/resize_cache/iblock/3fb/185_172_2/Kometa.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "8912",
    //       title: "JONY",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 22812,
    //   },
    //   genres: {
    //     code: "pop",
    //     title: "Поп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "jony/kometa/piano-easy",
    //       offer_id: 22811,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "jony/kometa/piano-solo",
    //       offer_id: 22812,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "jony/kometa/piano-vocal",
    //       offer_id: 22813,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "37747",
    //   title: "Ягода малинка",
    //   code: "yagoda-malinka",
    //   rating: "3.66",
    //   year: "2020",
    //   img: {
    //     src: "/upload/resize_cache/iblock/274/185_172_2/YAgoda-malinka.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "37746",
    //       title: "Хабиб",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 37749,
    //   },
    //   genres: {
    //     code: "pop",
    //     title: "Поп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "habib/yagoda-malinka/piano-easy",
    //       offer_id: 37748,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "habib/yagoda-malinka/piano-solo",
    //       offer_id: 37749,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "habib/yagoda-malinka/piano-vocal",
    //       offer_id: 37750,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "6782",
    //   title: "Вечера",
    //   code: "vechera",
    //   rating: "3.56",
    //   year: "2018",
    //   img: {
    //     src: "/upload/resize_cache/iblock/fcb/185_172_2/Rauf-_-Faik-_-Vechera.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "2918",
    //       title: "Rauf & Faik",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 6784,
    //   },
    //   genres: {
    //     code: "pop",
    //     title: "Поп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/vechera/piano-easy",
    //       offer_id: 6783,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/vechera/piano-solo",
    //       offer_id: 6784,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$9.30",
    //           VALUE: 9.3,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$14.10",
    //           VALUE: 14.1,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$18.70",
    //           VALUE: 18.7,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/vechera/piano-vocal",
    //       offer_id: 6785,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "11584",
    //   title: "Поезда",
    //   code: "poezda",
    //   rating: "0",
    //   year: "2019",
    //   img: {
    //     src: "/upload/resize_cache/iblock/df3/185_172_2/Tima-Belorusskikh-_-Poezda.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "1317",
    //       title: "Тима Белорусских",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 11586,
    //   },
    //   genres: {
    //     code: "rap",
    //     title: "Рэп/ хип-хоп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "tima-belorusskikh/poezda/piano-easy",
    //       offer_id: 11585,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "tima-belorusskikh/poezda/piano-solo",
    //       offer_id: 11586,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "tima-belorusskikh/poezda/piano-vocal",
    //       offer_id: 11587,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "584",
    //   title: "Люби меня, люби",
    //   code: "lyubi-menya-lyubi",
    //   rating: "3.45",
    //   year: "2017",
    //   img: {
    //     src: "/upload/resize_cache/iblock/405/185_172_2/Grechka-_-lyubi-menya-lyubi.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "583",
    //       title: "Гречка",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 23417,
    //   },
    //   genres: {
    //     code: "pop",
    //     title: "Поп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "grechka/lyubi-menya-lyubi/piano-vocal",
    //       offer_id: 585,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "grechka/lyubi-menya-lyubi/piano-easy",
    //       offer_id: 23416,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "grechka/lyubi-menya-lyubi/piano-solo",
    //       offer_id: 23417,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "10483",
    //   title: "Тёмно-оранжевый закат",
    //   code: "tyemno-oranzhevyy-zakat",
    //   rating: "2.9",
    //   year: "2018",
    //   img: {
    //     src: "/upload/resize_cache/iblock/c60/185_172_2/Papin-Olimpos-_-Tyemno_oranzhevyy-zakat.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "10482",
    //       title: "Папин Олимпос",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 10485,
    //   },
    //   genres: {
    //     code: "rock",
    //     title: "Рок",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "papin-olimpos/tyemno-oranzhevyy-zakat/piano-easy",
    //       offer_id: 10484,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "papin-olimpos/tyemno-oranzhevyy-zakat/piano-solo",
    //       offer_id: 10485,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "papin-olimpos/tyemno-oranzhevyy-zakat/piano-vocal",
    //       offer_id: 10486,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "6832",
    //   title: "Самый дорогой человек",
    //   code: "samyy-dorogoy-chelovek",
    //   rating: "3.42",
    //   year: "2017",
    //   img: {
    //     src: "/upload/resize_cache/iblock/183/185_172_2/Nervy-_-Samyy-dorogoy-chelovek.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "6831",
    //       title: "Нервы",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 6834,
    //   },
    //   genres: {
    //     code: "rock",
    //     title: "Рок",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "Nerves/samyy-dorogoy-chelovek/piano-easy",
    //       offer_id: 6833,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "Nerves/samyy-dorogoy-chelovek/piano-solo",
    //       offer_id: 6834,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "Nerves/samyy-dorogoy-chelovek/piano-vocal",
    //       offer_id: 6835,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "24655",
    //   title: "Положение",
    //   code: "polozhenie",
    //   rating: "3.75",
    //   year: "2017",
    //   img: {
    //     src: "/upload/resize_cache/iblock/8fb/185_172_2/Skriptonit-_-Polozhenie.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "1736",
    //       title: "Скриптонит",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$7.20",
    //     VALUE: 7.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 24657,
    //   },
    //   genres: {
    //     code: "rap",
    //     title: "Рэп/ хип-хоп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "skriptonit/polozhenie/piano-easy",
    //       offer_id: 24656,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "skriptonit/polozhenie/piano-solo",
    //       offer_id: 24657,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "skriptonit/polozhenie/piano-vocal",
    //       offer_id: 24658,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "46156",
    //   title: "Бесприданница",
    //   code: "bespridannitsa",
    //   rating: "3.33",
    //   year: "2021",
    //   img: {
    //     src: "/upload/resize_cache/iblock/72c/185_172_2/Bespridannitsa.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "35958",
    //       title: "DEAD BLONDE",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 46158,
    //   },
    //   genres: {
    //     code: "pop",
    //     title: "Поп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "dead-blonde/bespridannitsa/piano-easy",
    //       offer_id: 46157,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "dead-blonde/bespridannitsa/piano-solo",
    //       offer_id: 46158,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "dead-blonde/bespridannitsa/piano-vocal",
    //       offer_id: 46159,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "guitar-tab-easy",
    //       title: "Гитара.Табы (Лёгкие)",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "dead-blonde/bespridannitsa/guitar-tab-easy",
    //       offer_id: 46160,
    //       instruments: null,
    //     },
    //     {
    //       code: "guitar-tab",
    //       title: "Гитара.Табы",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "dead-blonde/bespridannitsa/guitar-tab",
    //       offer_id: 46161,
    //       instruments: [
    //         {
    //           code: "guitar",
    //           title: "Гитара",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "413",
    //   title:
    //     "Соната для фортепиано № 14 до-диез минор, ор. 27, № 2 (Лунная соната) Часть 1",
    //   code: "sonata-dlya-fortepiano-14-do-diez-minor-or-27-2-lunnaya",
    //   rating: "3.82",
    //   year: "1801",
    //   img: {
    //     src: "/upload/resize_cache/iblock/99d/185_172_2/111.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "386",
    //       title: "Людвиг ван Бетховен",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 414,
    //   },
    //   genres: {
    //     code: "classic",
    //     title: "Классика/ Современная классика",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "lyudvig-van-betkhoven/sonata-dlya-fortepiano-14-do-diez-minor-or-27-2-lunnaya/piano-solo",
    //       offer_id: 414,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$7.20",
    //           VALUE: 7.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$11",
    //           VALUE: 11,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$14.50",
    //           VALUE: 14.5,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "lyudvig-van-betkhoven/sonata-dlya-fortepiano-14-do-diez-minor-or-27-2-lunnaya/piano-easy",
    //       offer_id: 26886,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "2919",
    //   title: "Детство",
    //   code: "detstvo",
    //   rating: "3.55",
    //   year: "2018",
    //   img: {
    //     src: "/upload/resize_cache/iblock/542/185_172_2/Rauf-_-Faik-_-Detstvo.jpg",
    //     width: 185,
    //     height: 172,
    //   },
    //   musicians: [
    //     {
    //       id: "2918",
    //       title: "Rauf & Faik",
    //     },
    //   ],
    //   price: {
    //     PRINT_VALUE: "$5.20",
    //     VALUE: 5.2,
    //     CURRENCY: Currency.USD,
    //     STORE_ID: null,
    //     variant: "BASE",
    //     offer_id: 2921,
    //   },
    //   genres: {
    //     code: "pop",
    //     title: "Поп",
    //   },
    //   arrangements: [
    //     {
    //       code: "piano-easy",
    //       title: "Пианино.Easy",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/detstvo/piano-easy",
    //       offer_id: 2920,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-solo",
    //       title: "Пианино.Соло",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$5.20",
    //           VALUE: 5.2,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$7.90",
    //           VALUE: 7.9,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$10.40",
    //           VALUE: 10.4,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/detstvo/piano-solo",
    //       offer_id: 2921,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //     {
    //       code: "piano-vocal",
    //       title: "Пианино&Вокал",
    //       price: {
    //         BASE: {
    //           PRINT_VALUE: "$9.30",
    //           VALUE: 9.3,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         MIDI: {
    //           PRINT_VALUE: "$14.10",
    //           VALUE: 14.1,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //         FINAL: {
    //           PRINT_VALUE: "$18.70",
    //           VALUE: 18.7,
    //           CURRENCY: Currency.USD,
    //           STORE_ID: null,
    //         },
    //       },
    //       url: "rauf-faik/detstvo/piano-vocal",
    //       offer_id: 2922,
    //       instruments: [
    //         {
    //           code: "piano",
    //           title: "Пианино",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
  lang: Lang.RU,
  userID: null,
  filter: {
    IBLOCK_ID: 4,
  },
  pager: {
    page_current: 1,
    pages_all: 630,
    records_all: 18899,
    records_per_page: 30,
  },
};
