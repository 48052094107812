import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import { ENV } from '@shared/config';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { NotesListQueryKey, DeleteFavoriteMutationKey } from './keys';

export function useDeleteFavoriteMutation() {
  const qc = useQueryClient();

  return useMutation(
    [DeleteFavoriteMutationKey],
    async ({ offer_id }: { offer_id: string | number }) => {
      const token = getClientToken();

      const requestLink = `${ENV.API}/favorite/delete/?${token ? `token=${token}&` : ''}${queryString.stringify(
        {
          offer_id,
          lang: ENV.LANG,
        },
        {
          skipEmptyString: true,
        },
      )}`;

      await axios.get(requestLink, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
    {
      onSuccess: () => {
        void qc.invalidateQueries({
          queryKey: [NotesListQueryKey],
        });
      },
    },
  );
}
