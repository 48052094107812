import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryString from 'query-string';
import { ENV } from '@shared/config';
import { getCurrency } from '../../../app/currency';
// eslint-disable-next-line import/no-internal-modules
import { getClientToken } from '../../libs/auth-context';

import { BasketQueryKey } from './keys';

type TArg = {
  express: number[];
  promocode?: string;
};

type TCalcBasketResponse = {
  data: {
    PRICE: string;
    COUNTER: number;
    DISCOUNT: string;
    couponErrorCode: number;
    basketPrice: string;
    expressPrice: string;
    hasDiscount: boolean;
  };
};

export function useCalcBasketQuery({ express, promocode }: TArg) {
  const qw = express.length ? express.join(',') : undefined;

  return useQuery({
    queryKey: [BasketQueryKey, qw, promocode],
    queryFn: async () => {
      const token = getClientToken();

      // eslint-disable-next-line @typescript-eslint/await-thenable
      const currency = await getCurrency();

      const requestLink = `${ENV.API}/order_calc/?${queryString.stringify(
        {
          lang: ENV.LANG,
          token,
          currency: currency?.value || ENV.DEFAULT_CERRENCY,
          qw,
          promocode,
        },
        {
          skipEmptyString: true,
        },
      )}`;

      const { data } = await axios.get<unknown, TCalcBasketResponse>(requestLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return data as unknown as TCalcBasketResponse;
    },
    refetchOnWindowFocus: 'always',
  });
}
