import queryString from 'query-string';

import { ENV } from '@shared/config';
import type { TSearchResponse } from '@shared/types';

export async function getSearch({
  page = 1,
  q,
}: {
  q: string;
  page?: number;
}): Promise<TSearchResponse> {
  const requestLink = `${ENV.API}/search/?q=${q}&${queryString.stringify({
    page,
    pagelen: 20,
    lang: ENV.LANG,
  })}`;

  const res = await fetch(requestLink);
  const data = (await res.json()) as TSearchResponse;

  return data;
}
