import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { ENV } from '@shared/config';

export function useRatingMutation() {
  return useMutation(
    [],
    async ({ id, rating }: { id: number; rating: number }) => {
      await axios.get(`${ENV.API}/rating/add/?note_id=${id}&rating=${rating}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
    {},
  );
}
