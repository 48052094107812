import queryString from 'query-string';

import { ENV } from '@shared/config';
import { TListingPageResponse } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

type Arg = {
  params: string[];
  smart_url: string;
};

interface NotesResponse {
  breadcrumb: (
    | {
        title: string;
        url: string;
      }
    | {
        title: string;
        url: string;
      }[]
  )[];
  meta: {
    meta_description: string;
    meta_keywords: string;
    meta_title: string;
    meta_h1: string;
  };
}

export async function getCategoriesPage({ params, smart_url }: Arg): Promise<TListingPageResponse> {
  const requestLink = `${ENV.API}/get_menu/?${queryString.stringify({
    url: `/category/${params.join('/')}/`,
    lang: ENV.LANG,
  })}`;

  let h1 = '';

  let breadcrumb: (
    | {
        title: string;
        url: string;
      }
    | {
        title: string;
        url: string;
      }[]
  )[] = [];
  let meta: {
    meta_description: string;
    meta_keywords: string;
    meta_title: string;
  } = {
    meta_description: '',
    meta_keywords: '',
    meta_title: '',
  };

  const noChach = await getClientNoCache();

  const resData = await fetch(requestLink, {
    cache: noChach ? 'no-store' : undefined,
    next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_LIST_S, tags: ['NOTES'] },
  });
  const data = (await resData.json()) as TListingPageResponse;

  if (data.data && (data.data as Array<unknown>).length === 0) {
    const requestLinkNotes = `${ENV.API}/notes/?${smart_url ? `smart_url=${smart_url}&` : ''}${queryString.stringify(
      {
        append_filter: 'y',
        page: 1,
        pagelen: 18,
        lang: ENV.LANG,
      },
    )}`;

    const resNotesData = await fetch(requestLinkNotes, {
      cache: noChach ? 'no-store' : undefined,
      next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_LIST_S, tags: ['NOTES'] },
    });
    const notesData = (await resNotesData.json()) as NotesResponse;

    breadcrumb = notesData.breadcrumb;
    meta = notesData.meta;
    h1 = notesData.meta.meta_h1;
  } else {
    breadcrumb = data.breadcrumb || [];
    meta = data.meta || {
      meta_description: '',
      meta_keywords: '',
      meta_title: '',
      meta_h1: '',
    };
    h1 = data.meta?.meta_h1 || data.data[0]?.h1 || data.data[0]?.title || '';
  }

  return { ...data, breadcrumb, meta: { ...meta, meta_h1: h1 }, h1 };
}
