import { ENV } from '@shared/config';
import { TKeywordsResponse } from '@shared/types';

// eslint-disable-next-line import/no-internal-modules
import { getClientNoCache } from '../libs/auth-context/token';

export async function getKeywords(): Promise<TKeywordsResponse> {
  const requestLink = `${ENV.API}/get_musicians_alpha/`;

  const noChach = await getClientNoCache();

  const res = await fetch(requestLink, {
    cache: noChach ? 'no-store' : undefined,
    next: noChach ? undefined : { revalidate: ENV.REVALIDATE_NOTE_LIST_S, tags: ['NOTES'] },
  });
  const data = (await res.json()) as TKeywordsResponse;

  return data;
}
