import { logoIcon } from '../../assets';
import { CONTACT, ENV } from '../../config';
import type { Locale } from '../i18n';

type TArg = {
  locale: Locale;
};

export const getOrganization = ({ locale }: TArg) => ({
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: locale.OG_ORGANIZATION_NAME,
  alternateName: locale.OG_ORGANIZATION_ALTERNATIVE_NAME,
  description: locale.OG_ORGANIZATION_DESCRIPTION,
  url: locale.OG_ORGANIZATION_URL,
  email: locale.OG_ORGANIZATION_EMAIL,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  logo: `${ENV.STORAGE}${(logoIcon as unknown as { src: string }).src}`,
  sameAs: [CONTACT.VK, CONTACT.FS, CONTACT.PINTEREST],
  address: {
    '@type': 'PostalAddress',
    addressCountry: locale.OG_ORGANIZATION_ADDRESS_COUNTRY,
    streetAddress: locale.OG_ORGANIZATION_ADDRESS_STREET,
    postalCode: locale.OG_ORGANIZATION_ADDRESS_CODE,
    addressLocality: locale.OG_ORGANIZATION_ADDRESS_LOCATION,
  },
  telephone: locale.OG_ORGANIZATION_ADDRESS_PHONE,
});
